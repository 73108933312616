<ng-content select="[slot=before]"></ng-content>
<input
  class="spot-text-field--input"
  [attr.name]="name"
  [disabled]="disabled"
  [placeholder]="placeholder"
  [ngModel]="value"
  (ngModelChange)="valueChanged($event)"
  (focus)="onInputFocus($event)"
  (blur)="onInputBlur($event)"
  #input
/>
<button
  *ngIf="showClearButton && value !== '' && !disabled"
  (click)="valueChanged('')"
  type="button"
  class="spot-text-field--clear-button"
>
  <span class="spot-icon spot-icon_close"></span>
</button>
<ng-content select="[slot=after]"></ng-content>
