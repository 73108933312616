<form
  [formGroup]="principalForm"
  (ngSubmit)="onSubmit($event)"
>
  <div class="spot-modal--header">{{text.principal.title()}}</div>

  <div class="spot-divider"></div>

  <div class="spot-modal--body spot-container">
    <op-form-field
      [label]="textLabel"
      required
    >
      <op-ium-principal-search
        *ngIf="!(hasPrincipalSelected && isNewPrincipal)"
        [opFormBinding]="principalControl"
        [type]="type"
        [project]="project"
        slot="input"
        (createNew)="createNewFromInput($event)"
      ></op-ium-principal-search>

      <p
        *ngIf="isNewPrincipal && type === PrincipalType.User"
        slot="input"
      >
        <b>{{ text.principal.inviteUser }}</b> {{ principal.name }}
        <button
          type="button"
          class="spot-link"
          (click)="principalControl?.setValue(null)"
        >{{ text.principal.change }}</button>
      </p>

      <p
        *ngIf="isNewPrincipal && type === PrincipalType.Placeholder"
        slot="input"
      >
        <b>{{ text.principal.createNewPlaceholder }}</b> {{ principal.name }}
        <button
          type="button"
          class="spot-link"
          (click)="principalControl?.setValue(null)"
        >{{ text.principal.change }}</button>
      </p>

      <div
        slot="errors"
        class="op-form-field--error"
        *ngIf="principalControl?.invalid"
      >
        {{ text.principal.required[type] }}
      </div>
    </op-form-field>

    <op-dynamic-form
      *ngIf="isNewPrincipal && type === PrincipalType.User && userDynamicFieldConfig.schema"
      [dynamicFormGroup]="dynamicFieldsControl"
      [settings]="userDynamicFieldConfig"
      [formUrl]="apiV3Service.users.form.path"
      [handleSubmit]="false"
    ></op-dynamic-form>

    <op-form-field
      [label]="text.role.label()"
      required
    >
      <op-ium-role-search
        [opFormBinding]="roleControl"
        slot="input"
      ></op-ium-role-search>
      <p
        class="op-form-field--description"
        slot="help-text"
        [innerHtml]="text.role.description()"
      ></p>
    
      <div
        slot="errors"
        class="op-form-field--error"
        *ngIf="roleControl?.invalid"
        >
          {{ text.role.required }}
        </div>
    </op-form-field>

    <op-form-field 
      [label]="text.message.label"
      *ngIf="type !== PrincipalType.Placeholder"
    >
    
      <textarea
        class="op-input op-ium-invite-message-field"
        [formControl]="messageControl"
        slot="input"
        #input>
      </textarea>

      <p class="op-form-field--description" slot="help-text">
        {{ text.message.description() }}
      </p>
    </op-form-field>
  </div>

  <div class="spot-action-bar">
    <div class="spot-action-bar--left">
      <button
        type="button"
        class="button button_no-margin spot-action-bar--action"
        (click)="back.emit()"
        >
        <op-icon icon-classes="button--icon icon-arrow-left1"></op-icon>
        {{ text.principal.backButton }}
      </button>
    </div>

    <div class="spot-action-bar--right">
      <button
        type="button"
        class="button button_no-margin spot-action-bar--action"
        (click)="close.emit()"
        >
        {{ text.principal.cancelButton }}
     </button>
      <button class="button button_no-margin -highlight spot-action-bar--action">{{ text.principal.nextButton }}</button>
    </div>
  </div>
</form>
